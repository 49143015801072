// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-android-privacy-policy-js": () => import("./../../../src/pages/android-privacy-policy.js" /* webpackChunkName: "component---src-pages-android-privacy-policy-js" */),
  "component---src-pages-bug-type-pokemon-go-js": () => import("./../../../src/pages/bug-type-pokemon-go.js" /* webpackChunkName: "component---src-pages-bug-type-pokemon-go-js" */),
  "component---src-pages-current-raid-bosses-js": () => import("./../../../src/pages/current-raid-bosses.js" /* webpackChunkName: "component---src-pages-current-raid-bosses-js" */),
  "component---src-pages-dark-type-pokemon-go-js": () => import("./../../../src/pages/dark-type-pokemon-go.js" /* webpackChunkName: "component---src-pages-dark-type-pokemon-go-js" */),
  "component---src-pages-dragon-type-pokemon-go-js": () => import("./../../../src/pages/dragon-type-pokemon-go.js" /* webpackChunkName: "component---src-pages-dragon-type-pokemon-go-js" */),
  "component---src-pages-electric-type-pokemon-go-js": () => import("./../../../src/pages/electric-type-pokemon-go.js" /* webpackChunkName: "component---src-pages-electric-type-pokemon-go-js" */),
  "component---src-pages-fairy-type-pokemon-go-js": () => import("./../../../src/pages/fairy-type-pokemon-go.js" /* webpackChunkName: "component---src-pages-fairy-type-pokemon-go-js" */),
  "component---src-pages-fighting-type-pokemon-go-js": () => import("./../../../src/pages/fighting-type-pokemon-go.js" /* webpackChunkName: "component---src-pages-fighting-type-pokemon-go-js" */),
  "component---src-pages-fire-type-pokemon-go-js": () => import("./../../../src/pages/fire-type-pokemon-go.js" /* webpackChunkName: "component---src-pages-fire-type-pokemon-go-js" */),
  "component---src-pages-flying-type-pokemon-go-js": () => import("./../../../src/pages/flying-type-pokemon-go.js" /* webpackChunkName: "component---src-pages-flying-type-pokemon-go-js" */),
  "component---src-pages-ghost-type-pokemon-go-js": () => import("./../../../src/pages/ghost-type-pokemon-go.js" /* webpackChunkName: "component---src-pages-ghost-type-pokemon-go-js" */),
  "component---src-pages-grass-type-pokemon-go-js": () => import("./../../../src/pages/grass-type-pokemon-go.js" /* webpackChunkName: "component---src-pages-grass-type-pokemon-go-js" */),
  "component---src-pages-ground-type-pokemon-go-js": () => import("./../../../src/pages/ground-type-pokemon-go.js" /* webpackChunkName: "component---src-pages-ground-type-pokemon-go-js" */),
  "component---src-pages-ice-type-pokemon-go-js": () => import("./../../../src/pages/ice-type-pokemon-go.js" /* webpackChunkName: "component---src-pages-ice-type-pokemon-go-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-normal-type-pokemon-go-js": () => import("./../../../src/pages/normal-type-pokemon-go.js" /* webpackChunkName: "component---src-pages-normal-type-pokemon-go-js" */),
  "component---src-pages-poison-type-pokemon-go-js": () => import("./../../../src/pages/poison-type-pokemon-go.js" /* webpackChunkName: "component---src-pages-poison-type-pokemon-go-js" */),
  "component---src-pages-pokedex-pokemon-go-js": () => import("./../../../src/pages/pokedex-pokemon-go.js" /* webpackChunkName: "component---src-pages-pokedex-pokemon-go-js" */),
  "component---src-pages-psychic-type-pokemon-go-js": () => import("./../../../src/pages/psychic-type-pokemon-go.js" /* webpackChunkName: "component---src-pages-psychic-type-pokemon-go-js" */),
  "component---src-pages-rock-type-pokemon-go-js": () => import("./../../../src/pages/rock-type-pokemon-go.js" /* webpackChunkName: "component---src-pages-rock-type-pokemon-go-js" */),
  "component---src-pages-steel-type-pokemon-go-js": () => import("./../../../src/pages/steel-type-pokemon-go.js" /* webpackChunkName: "component---src-pages-steel-type-pokemon-go-js" */),
  "component---src-pages-water-type-pokemon-go-js": () => import("./../../../src/pages/water-type-pokemon-go.js" /* webpackChunkName: "component---src-pages-water-type-pokemon-go-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-pokemon-index-js": () => import("./../../../src/templates/pokemon/index.js" /* webpackChunkName: "component---src-templates-pokemon-index-js" */)
}

